import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { TwitterFollowButton } from 'react-twitter-embed';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query SocialQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => {
      const { title } = data.site.siteMetadata;
      return (
        <footer
          className="footer center "
          style={{ padding: '6rem 1.5rem 3rem' }}
        >
          <div className="container content is-narrow">
            <p className="has-text-size-small is-size-7-desktop">
              &copy; 2019 {title}. LHS Downtown an
              informational website focused on providing updates on the Lowell
              High School project. It is not endorsed by, directly affiliated
              with, maintained, authorized, or sponsored by the City of Lowell.
              The views, thoughts, and opinions expressed by this website belong
              solely to the authors. For all official communications, please see
              the{' '}
              <a
                className="has-text-weight-bold"
                href="http://lowellhsproject.com/"
              >
                City of Lowell's High School Project website
              </a>
              .
            </p>
            <div className="has-text-centered">
              <TwitterFollowButton screenName="lhsdowntown" />
            </div>
          </div>
        </footer>
      );
    }}
  />
);

export default Footer;
